import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql, Link } from 'gatsby'
import { useLocation } from '@reach/router'

import Navigation from './Navigation'
import './Layout.scss'

export const SettingsContext = React.createContext()

const Layout = ({ isNotPadded, children }) => {
  const { settings, projects } = useStaticQuery(graphql`
    query Settings {
      settings: allStoryblokEntry(
        filter: { field_component: { eq: "Settings" } }
      ) {
        edges {
          node {
            name
            full_slug
            content
          }
        }
      }
      projects: allStoryblokEntry(
        filter: { field_component: { eq: "Project" } }
      ) {
        edges {
          node {
            uuid
            name
            full_slug
          }
        }
      }
    }
  `)

  const settingsContent = JSON.parse(settings.edges[0].node.content)
  const location = useLocation()
  const isProjectsPage = location.pathname.startsWith('/projects')
  const paddingStyle = isProjectsPage ? {} : { paddingBottom: '50px' }
  const highlightedProject =
    projects.edges.find(
      (p) => p.node.uuid === settingsContent.highlighted_project,
    )?.node || {}

  return (
    <SettingsContext.Provider value={settingsContent}>
      <div className="page__wrapper" style={paddingStyle}>
        <Navigation links={settingsContent.navigation} />
        <main className={isNotPadded ? '' : ''}>{children}</main>
        {!isProjectsPage && (
          <footer className="page__footer">
            <Link to={`/${highlightedProject.full_slug}`}>
              {highlightedProject.name}
            </Link>
          </footer>
        )}
      </div>
    </SettingsContext.Provider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
