import Link from 'gatsby-link'
import React from 'react'

import './styles.scss'

const Navigation = ({ links }) => {
  const parseLink = (link) => link.replace('home', '')

  return (
    <header className="navigation">
      <nav role="navigation">
        <ul>
          {links &&
            links.map((link, index) => (
              <li key={index}>
                <Link
                  className="navigation__link"
                  activeClassName="navigation__link--active"
                  to={`/${parseLink(link.link.cached_url)}`}
                >
                  {link.name}
                </Link>
              </li>
            ))}
        </ul>
      </nav>
    </header>
  )
}

export default Navigation
